import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { IoMdOpen } from "react-icons/io";
import { IoPlay } from "react-icons/io5";
import { GatsbyImage } from "gatsby-plugin-image";

import { useModal } from "../components/useModal";

import { Container, Wrapper, Row, Box } from "../components/util";
import { CallToAction, Modal } from "../components/site";
import Layout from "../components/layout";
import Seo from "../components/seo";

import PayHeroGraphic from "../images/graphics/PayHeroSupport_Graphic.svg";

const VideoRow = styled(Row)`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% + 30px);
  }
`;

const FilterBox = styled(Box)`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: sticky;
    top: 100px;
  }
`;

const FilterItem = styled.a`
  display: block;
  padding: 10px 18px;
  background-color: ${(props) => props.theme.colours.white};
  color: ${(props) => props.theme.colours.dark};
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) {
    &.-active {
      &:hover {
        background-color: ${(props) => props.theme.colours.blue};
        color: ${(props) => props.theme.colours.white};
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colours.midGrey};
    }
  }

  &.-active {
    background-color: ${(props) => props.theme.colours.blue};
    color: ${(props) => props.theme.colours.white};
  }
`;

const VideoCardContainer = styled.div`
  position: relative;
  box-shadow: ${(props) => props.theme.styles.shadow};
  border-radius: 20px;
  width: calc((100% / 2) - 30px);
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-right: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    &.-full {
      width: 100%;
      padding-bottom: 0;

      .play-circle {
        height: 100px;
        width: 100px;

        svg {
          font-size: 40px;
        }
      }

      &:hover {
        .play-circle {
          height: 110px;
          width: 110px;
        }
      }
    }
  }

  .gatsby-image-wrapper {
    border-radius: 20px;
  }

  &:hover {
    .play-circle {
      height: 80px;
      width: 80px;
    }
  }

  .play-circle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-decoration: none;
    bottom: 30px;
    right: 30px;
    height: 70px;
    width: 70px;
    border-radius: 100px;
    /* transform: translate(-50%, -50%); */
    background: #fff;
    box-shadow: ${(props) => props.theme.styles.shadow};
    transition: all ${(props) => props.theme.transitions.med};

    svg {
      font-size: 30px;
      color: ${(props) => props.theme.colours.dark};
    }
  }
`;

const PayHeroTutorials = (props) => {
  var [embedLink, setEmbedLink] = useState("");
  var [embedTitle, setEmbedTitle] = useState("");
  const [itemModalOpen, setItemModalOpen] = useModal();

  useEffect(() => {
    if (props.location.search.startsWith("?video")) {
      var video = props.location.search.substr(7);
      setItemModalOpen(true);
      setEmbedLink(
        "https://player.vimeo.com/video/" +
          (video.indexOf("&") !== -1
            ? video.substring(0, video.indexOf("&"))
            : video)
      );
      setEmbedTitle("PayHero Guide");
    }
  }, [props.location.search, embedLink, embedTitle]);

  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulTutorialVideo(sort: { fields: videoTitle, order: ASC }) {
        edges {
          node {
            thumbnail {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            embedUrl
            topic
            videoTitle
          }
        }
      }
    }
  `);

  const filters = [
    "All",
    "Payroll",
    "Timesheets",
    "Leave",
    "Reporting",
    "Work",
    "IRD Integration",
    "Teams",
  ];

  const [filterVal, setFilterVal] = useState("All");

  const handleChange = function(e) {
    setFilterVal(e.target.name);
  };

  function handleModal(url, title) {
    setItemModalOpen(true);
    setEmbedLink(url);
    setEmbedTitle(title);
  }

  const showVideo = (video) => {
    if (video.topic === filterVal || filterVal === "All") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Layout>
      <Seo
        title="PayHero Tutorial Videos | Payroll Guides"
        description="Watch our bite-sized how to videos to learn how to master the different features and functions in PayHero's payroll software."
        pathname={props.location.pathname}
      />
      <Container bg="Primary" className="-textCenter -whiteOut">
        <Wrapper stackGap={80}>
          <Box stackGap={10}>
            <h1 className="h4 -fontBold" css={{ color: "#333" }}>
              <IoPlay css={{ top: "2px", position: "relative" }} /> PayHero
              Tutorial Videos
            </h1>
            <h4
              className="h1 -fontLarge"
              css={{ color: "#333", fontSize: "6rem" }}
            >
              Watch & Learn
            </h4>
          </Box>
        </Wrapper>
      </Container>
      <Container css={{ height: "100%", overflow: "inherit" }}>
        <Wrapper stackGap={80}>
          <Row stackGap={40}>
            <FilterBox size={20} stackGap={30}>
              <Box stackGap={7}>
                {filters.map((item, i) => {
                  return (
                    <FilterItem
                      className={item === filterVal && "-active"}
                      onClick={handleChange}
                      name={item}
                      key={i}
                    >
                      {item}
                    </FilterItem>
                  );
                })}
              </Box>
              <p>
                For more help, visit the{" "}
                <a
                  href="https://support.payhero.co.nz/hc/en-us"
                  target="_blank"
                >
                  PayHero Support Centre <IoMdOpen css={{ top: "2px" }} />
                </a>
                .
              </p>
            </FilterBox>
            <Box size={80}>
              <VideoRow isWrap justify="flex-start">
                {contentfulData.allContentfulTutorialVideo.edges
                  .filter(function(item) {
                    return showVideo(item.node);
                  })
                  .map((edge, i) => {
                    return (
                      <VideoCardContainer
                        key={i}
                        className={
                          edge.node.videoTitle === "A Getting Started"
                            ? "-full"
                            : ""
                        }
                        onClick={() => {
                          handleModal(edge.node.embedUrl, edge.node.videoTitle);
                        }}
                      >
                        {edge.node.thumbnail && (
                          <GatsbyImage
                            image={edge.node.thumbnail.gatsbyImageData}
                            alt={
                              edge.node.videoTitle + ` | PayHero Video Guide`
                            }
                            title={
                              edge.node.videoTitle + ` | PayHero Video Guide`
                            }
                          />
                        )}
                        <div className="play-circle">
                          <IoPlay />
                        </div>
                      </VideoCardContainer>
                    );
                  })}
              </VideoRow>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <CallToAction />
      <Modal
        isActive={itemModalOpen}
        embedURL={embedLink + `?autoplay=1`}
        embedTitle={embedTitle + ` | PayHero Video Guide`}
        handleClose={() => setItemModalOpen(false)}
        isVideo
      />
    </Layout>
  );
};

export default PayHeroTutorials;
